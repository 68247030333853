@font-face {
  font-family: "Inria Sans";
  font-weight: 300;
  font-style: normal;
  src: url("./scss/webfonts/InriaSans-Light.woff2") format("woff2");
}

@font-face {
  font-family: "Inria Sans";
  font-weight: 300;
  font-style: italic;
  src: url("./scss/webfonts/InriaSans-LightItalic.woff2") format("woff2");
}

@font-face {
  font-family: "Inria Sans";
  font-weight: 400;
  font-style: normal;
  src: url("./scss/webfonts/InriaSans-Regular.woff2") format("woff2");
}

@font-face {
  font-family: "Inria Sans";
  font-weight: 400;
  font-style: italic;
  src: url("./scss/webfonts/InriaSans-Italic.woff2") format("woff2");
}

@font-face {
  font-family: "Inria Sans";
  font-weight: 700;
  font-style: normal;
  src: url("./scss/webfonts/InriaSans-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Inria Sans";
  font-weight: 700;
  font-style: italic;
  src: url("./scss/webfonts/InriaSans-BoldItalic.woff2") format("woff2");
}
