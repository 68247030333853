#site-header-wrap {
  background-color: rgba(0, 120, 161, 0.8);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  box-shadow: rgba(0, 0, 0, 0.4) 0 1px 2rem;
  position: fixed;
  z-index: 3;
  width: 100%;
}

#site-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
}

#site-header a {
  color: white;
}

#site-title {
  position: relative;
  display: flex;
  align-items: center;
  img {
    display: block;
    width: 44px;
    height: auto;
    margin-right: 0.7rem;
  }
  span {
    @include fonts-headings;
    font-weight: 500;
    position: relative;
    font-size: 1rem;
    margin-top: 0.4rem;
    letter-spacing: 0.03em;
    line-height: 1;
  }
  @include medium-screen {
    font-size: 1.1rem;
  }
}

#site-nav {
  ul {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    li {
      list-style: none;
      &:not(:last-child) {
        margin-right: 1rem;
        @include medium-screen {
          margin-right: 1.67rem;
        }
      }
      a {
        font-family: $font-stack-headings;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 0.85rem;
        font-weight: 400;
        line-height: 1.1;
        letter-spacing: 0.08rem;
      }
    }
  }
}
