#home-header {
  background-image: url("./scss/home-header.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem;
  margin-bottom: 3rem;
  .container {
    padding-top: 0;
  }
  @include small-screen-only {
    min-height: auto;
    padding: 4rem 0;
    min-height: 400px;
  }
  h1 {
    color: #d7d7d7;
    font-weight: 700;
    font-family: $font-stack-headings;
    font-size: 2.8rem;
    line-height: 1.2;
    mix-blend-mode: color-dodge;
    @include medium-screen {
      font-size: 3.4rem;
    }
    @include large-screen {
      font-size: 3.8rem;
    }
    em,
    strong {
      font-weight: 700;
      font-style: normal;
    }
  }
  &.parallax {
    position: fixed;
  }
}

#home-parallax-wrap {
  position: relative;
  background-color: white;
  main.container {
    padding-top: 3rem;
  }
}

#intro {
  margin-bottom: 3rem;
}

$item-padding: 1.67rem;
$item-padding-medium: 3rem;

#work {
  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    position: relative;
    width: calc(100% + 2.8rem);
    left: -1.4rem;
    li {
      width: 100%;
      list-style: none;
      @include medium-screen {
        width: 50%;
      }
      a {
        display: block;
        padding: 1.4rem;
        transition: background-color 0.4s ease;
        &:hover {
          background-color: #eef7fc;
        }
        .sr-only {
          visibility: hidden;
          position: absolute;
          left: -9999px;
        }
        img {
          display: block;
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
