@import "bootstrap-reboot";
@import "webfonts/inria-sans";
@import "webfonts/cooper-hewitt";

$wrapper-max: 950px;
$wrapper-wide-max: 1100px;
$wrapper-width: 84vw;
$margin-small: 1.5rem;

$gray: #706a63;
$text-color: #312e2f;
$link-color: #1abacd;

$font-stack-headings: "Cooper Hewitt", system-ui, sans-serif;

@mixin small-screen-only {
  @media (max-width: 800px) {
    @content;
  }
}

@mixin medium-screen {
  @media (min-width: 801px) {
    @content;
  }
}

@mixin large-screen {
  @media (min-width: 1300px) {
    @content;
  }
}

@mixin fonts-headings {
  font-family: $font-stack-headings;
  font-style: normal;
  text-transform: uppercase;
  font-weight: 700;
}

@mixin button {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  background: none;
  text-align: center;
  font-size: 1rem;
}

html {
  font-size: 16px;
  @include medium-screen {
    font-size: 19px;
  }
}

body {
  font-family: "Inria Sans", system-ui, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  background-color: white;
  color: $text-color;
}

.container {
  max-width: $wrapper-max;
  margin-left: auto;
  margin-right: auto;
  padding-left: $margin-small;
  padding-right: $margin-small;
  @include medium-screen {
    width: $wrapper-width;
  }
  &.wide {
    max-width: $wrapper-wide-max;
  }
}

main.container {
  padding-top: 6rem;
  h1,
  h2 {
    @include fonts-headings;
    line-height: 1.2;
    padding-top: 0.7rem;
    border-top: 3px solid $text-color;
  }
  h1 {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    @include medium-screen {
      font-size: 2.5rem;
    }
  }
  h2 {
    font-size: 1.5rem;
    @include medium-screen {
      font-size: 1.8rem;
    }
  }
  p {
    font-size: 1.2rem;
    line-height: 1.45;
  }
  p a,
  li a {
    color: $link-color;
    font-weight: 700;
  }
}

#wrapper {
  position: relative;
  z-index: 2;
  background-color: white;
}

.full-width {
  width: 100vw;
  max-width: 100vw;
  margin-left: calc(50% - 50vw);
}

p {
  font-size: 1.15rem;
  color: #413b3d;
}

.inline-image {
  display: inline-block;
  float: right;
  max-width: 30%;
  &.right {
    margin-left: 2rem;
    margin-bottom: 2rem;
  }
}

img {
  display: block;
  max-width: 100%;
  max-height: 60vh;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  height: auto;
}

button.action {
  appearance: none;
  border-radius: 5px;
  border: none;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  padding: 0.4rem 0.6rem;
  background-color: #1abacd;
  &:active {
    background-color: #0099ab;
  }
}
